import { I18nContext } from '@deity-io/falcon-i18n';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './LogoutSuccess.scss';

const LogoutSuccess = () => {
  const { t } = useContext(I18nContext);
  const history = useHistory();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.replace('/');
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="logout-success">
      <h2 className="logout-success__title">{t('user.loggedOut')}</h2>
      <p className="logout-success__message">{t('user.loggedOutMessage')}</p>
    </div>
  );
};

export default LogoutSuccess;
